import { useCallback, useMemo } from 'react';

import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next';
import config from 'src/config';

import FontAwesomeIcon from '@components/atoms/fontAwesomeIcon';
import { MenuItemProps } from '@components/molecules/verticalMenuList';
import { Role, ROUTES, UserType } from '@helpers/constants';
import { useFormatNumber } from '@helpers/use';
import { withParenthesis } from '@helpers/utils';
import { useProfile } from '@hooks/useProfile';

export type MenuItemGroup = {
  label: string;
  isHideTitle: boolean;
  menuItems: MenuItemProps[];
};

export type UseUserMenuListingProps = {
  excluded?: string[];
};

const useUserMenuListing = ({ excluded = [] }: UseUserMenuListingProps = {}) => {
  const { t } = useTranslation();
  const { profile } = useProfile();

  const userType = profile?.role;
  const userRoles = profile?.roles || [];
  const isCPDUser = profile?.isCPDUser ?? false;
  const isAffiliatePartner = profile?.isAffiliatePartner ?? false;
  const loyaltyPoints = useFormatNumber(profile?.loyaltyPoints || 0, 5);
  const notificationCount = useFormatNumber(profile?.notificationCount || 0, 2);
  const instructorSlug = profile?.slug || '';

  const filterOnlyAllowedMenuItems = useCallback(
    (menuItems: MenuItemProps[]) =>
      menuItems.filter((menuItem) => {
        const isExcluded = excluded.includes(menuItem.key);
        if (isExcluded) {
          return false;
        }

        const isUnsetAllowed = _isEmpty(menuItem.allowed);
        const isUserTypeAllowed = userType && menuItem.allowed?.includes(userType);

        const isUserRoleAllowed = userRoles.some((role) => menuItem.allowed?.includes(role.name));

        return isUnsetAllowed || isUserTypeAllowed || isUserRoleAllowed;
      }),
    [userType, userRoles, excluded],
  );

  const menuList: MenuItemGroup[] = useMemo(() => {
    const extraMenuItems: MenuItemProps[] = [
      {
        key: 'system-admin',
        label: t('menu.system_admin'),
        icon: null,
        url: ROUTES.SYSTEM_ADMIN,
        allowed: [UserType.SYSTEM_ADMIN],
      },
      {
        key: 'examination',
        label: t('menu.examination'),
        icon: null,
        url: ROUTES.EVALUATION_PORTAL,
        allowed: [UserType.INSTRUCTOR_ASSISTANT],
      },
      {
        key: 'manage-organization',
        label: t('menu.manage_organization'),
        icon: null,
        url: ROUTES.ORGANIZATION_PORTAL,
        allowed: [UserType.ORGANIZATION_ADMIN],
      },
      {
        key: 'evaluation-result-list',
        label: t('evaluation_result_list'),
        icon: null,
        url: ROUTES.EVALUATION_RESULT_LIST,
        allowed: [Role.UNIVERSITY_ADMIN],
      },
    ];

    const studyInfoMenuItems: MenuItemProps[] = [
      {
        key: 'my-courses',
        label: t('menu.my_course'),
        icon: <FontAwesomeIcon icon="fa-light fa-book" />,
        url: ROUTES.MY_COURSE,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-academic-degrees',
        label: t('menu.my_academic_degree'),
        icon: <FontAwesomeIcon icon="fa-light fa-graduation-cap" />,
        url: ROUTES.MY_ACADEMIC_DEGREE,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-certificates',
        label: t('menu.my_certificate'),
        icon: <FontAwesomeIcon icon="fa-light fa-file-certificate" />,
        url: ROUTES.MY_CERTIFICATE,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-learning-path',
        label: 'Learning Path',
        icon: <FontAwesomeIcon icon="fa-regular fa-diploma" />,
        url: ROUTES.MY_LEARNING_PATH,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-academic-assignment',
        label: t('menu.my_academic_assignment'),
        icon: <FontAwesomeIcon icon="fa-light fa-pencil" />,
        url: ROUTES.MY_ACADEMIC_ASSIGNMENT,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
    ];

    const trainingCPDMenuItems: MenuItemProps[] = [
      ...(isCPDUser
        ? [
            {
              key: 'my-training-cpd',
              label: t('menu.my_training_cpd'),
              icon: <FontAwesomeIcon icon="fa-light fa-book-open-reader" />,
              url: config.CPD_DOMAIN,
              allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
            },
          ]
        : []),
    ];

    const profileInfoMenuItems: MenuItemProps[] = [
      {
        key: 'my-profile',
        label: t('menu.my_profile'),
        icon: <FontAwesomeIcon icon="fa-light fa-user" />,
        url: ROUTES.MY_PROFILE,
      },
      {
        key: 'my-wishlist-courses',
        label: t('menu.my_wishlist_course'),
        icon: <FontAwesomeIcon icon="fa-light fa-heart" />,
        url: ROUTES.MY_WISHLIST,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-payment-history',
        label: t('menu.my_payment_history'),
        icon: <FontAwesomeIcon icon="fa-light fa-clock-rotate-left" />,
        url: ROUTES.MY_PAYMENT,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-rewards',
        label: [t('menu.my_reward'), withParenthesis(loyaltyPoints)].join(' '),
        icon: <FontAwesomeIcon icon="fa-light fa-gift" />,
        url: ROUTES.MY_REWARD,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      {
        key: 'my-notifications',
        label: `${t('menu.my_notification')} ${withParenthesis(notificationCount)}`,
        icon: null,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
      },
      ...(isAffiliatePartner
        ? [
            {
              key: 'my-affiliate',
              label: t('menu.my_affiliate'),
              icon: <FontAwesomeIcon icon="fa-light fa-link" />,
              url: ROUTES.MY_AFFILIATE,
              allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR],
            },
          ]
        : []),
      {
        key: 'notification-setting',
        label: t('menu.notification_setting'),
        icon: <FontAwesomeIcon icon="fa-light fa-gear" />,
        url: ROUTES.MY_NOTIFICATION,
        allowed: [UserType.STUDENT, UserType.SYSTEM_ADMIN, UserType.INSTRUCTOR, UserType.INSTRUCTOR_ASSISTANT],
      },
    ];

    const instructorTeachingMenuItems: MenuItemProps[] = [
      {
        key: 'my-teaching',
        label: t('menu.my_teaching'),
        icon: <FontAwesomeIcon icon="fa-light fa-chalkboard-user" />,
        url: ROUTES.MY_TEACHING,
        allowed: [UserType.INSTRUCTOR],
      },
      {
        key: 'my-instructor-homepage',
        label: t('menu.my_instructor_homepage'),
        icon: <FontAwesomeIcon icon="fa-light fa-browser" />,
        url: ROUTES.MY_INSTRUCTOR_HOMEPAGE.replace(':slug', instructorSlug),
        allowed: [UserType.INSTRUCTOR],
      },
      {
        key: 'my-instructor-profile',
        label: t('menu.my_instructor_profile'),
        icon: <FontAwesomeIcon icon="fa-light fa-address-card" />,
        url: ROUTES.MY_INSTRUCTOR_PROFILE,
        allowed: [UserType.INSTRUCTOR],
      },
      {
        key: 'my-instructor-financial',
        label: t('menu.my_instructor_financial'),
        icon: <FontAwesomeIcon icon="fa-light fa-money-check-pen" />,
        url: ROUTES.MY_INSTRUCTOR_FINANCIAL,
        allowed: [UserType.INSTRUCTOR],
      },
    ];

    return [
      {
        label: t('menu.extra'),
        isHideTitle: true,
        menuItems: filterOnlyAllowedMenuItems(extraMenuItems),
      },
      {
        label: t('menu.study_information'),
        isHideTitle: false,
        menuItems: filterOnlyAllowedMenuItems(studyInfoMenuItems),
      },
      {
        label: t('menu.training_skilllane_cpd'),
        isHideTitle: false,
        menuItems: filterOnlyAllowedMenuItems(trainingCPDMenuItems),
      },
      {
        label: t('menu.profile_information'),
        isHideTitle: false,
        menuItems: filterOnlyAllowedMenuItems(profileInfoMenuItems),
      },
      {
        label: t('menu.instructor_teaching'),
        isHideTitle: false,
        menuItems: filterOnlyAllowedMenuItems(instructorTeachingMenuItems),
      },
    ];
  }, [isCPDUser, isAffiliatePartner, loyaltyPoints, notificationCount, instructorSlug, filterOnlyAllowedMenuItems, t]);

  return {
    menuList,
  };
};

export default useUserMenuListing;
